.container {
    text-align: center;
    padding: 50px;
    background-color: #f0f0f0;

    h2 {
        margin-bottom: 20px;
    }

    .contactForm {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        input,
        textarea {
            width: 90%;
            padding: 10px;
            margin: 0 auto;
        }

        button {
            padding: 10px 20px;
            background-color: #333;
            color: #fff;
            border: none;
            cursor: pointer;
        }
    }
}
