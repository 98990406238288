@import "../../../../Variables.scss";

.section {
    position: relative;
}

.media {
    width: 100px;
    height: auto;

    &-video {
        width: 350px;
        height: auto;
    }

    &-container {
        display: flex;
    }
}

.option {
    padding: 7px;
}

.options {
    position: absolute;
    z-index: $global-z-index-3;
    border-radius: 16px;
    background-color: white;
    left: -50px;
    top: 50px;
    padding: 10px;

    &-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: var(--framer-fresco-popover-shadow, 0px 1px 8px 0px rgba(0, 0, 0, .05), 0px 5px 20px 0px rgba(0, 0, 0, .08));
        width: 260px;
        border-radius: 16px;
    }

    &-title {
        color: black;
        font-weight: lighter;
    }

    &-input {
        cursor: pointer;
    }

    &-preview {
        position: relative;

        &-img {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }
}