.navigation-link {
    a {
        text-decoration: none;
        transition: color 0.3s ease;

        outline: none;
        user-select: none;
        font-weight: 500;
        line-height: 1;
        border-radius: 4px;
        color: var(--accent-3);

        &:hover {
            text-decoration: underline;
        }
    }
}
