.hero {
    text-align: center;
    padding: 70px 20px 40px;
    font-family: var(--font-family-body);
    background-color: var(--color-background);
    color: var(--color-text);

    &-title {
        margin-bottom: 20px;
        font-size: 3rem;
        color: var(--accent-9);
    }

    .button {
        min-width: 300px;
        min-height: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1rem;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        object-position: center center;
        object-fit: cover;
        image-rendering: auto;

        &-frame {
            position: relative;
            aspect-ratio: 1.77778 / 1;
            border-radius: 20px;
            width: 55%;

        }

        &-wrapper {
            position: absolute;
            border-radius: inherit;
            inset: 0px;
        }
    }

    .text {
        padding: 20px;
        font-weight: 400;
        margin-bottom: 30px;
        font-size: 1.2rem;
        color: var(--color-text);

        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }
}