@import "../../TemplateVariables.scss";

.header-sticky+section,
.header-sticky+div {
    margin-top: -100px !important;
}

.header-sticky {
    position: sticky;
    top: 0;
    z-index: $global-z-index-2;
    padding: 10px;

    transition: background-color 0.3s;
    background-color: transparent;
    font-family: var(--font-family-body);

    container-type: inline-size;
    container-name: headerSticky;

    @container headerSticky (max-width: 600px) {
        .header-sticky {
            &-container {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    &-container {
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px 40px;
        align-items: center;
        max-width: 1200px;
        margin: auto;
    }

    &.scrolled {
        background-color: var(--accent-9);
    }
}