.input {
    &-textarea {
        min-width: 300px;
    }

    &-button {
        width: 100%;
        margin-top: 10px;

        &-icon {
            width: 10px;
            height: 10px;
        }
    }
}