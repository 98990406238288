.preview {
    height: 200px;
    width: 200px;
    border-radius: 5px;
}

.upload {
    &-input {
        display: none;
    }

    &-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-icon {
        width: 15px;
        height: 15px;
    }
}