@import "../../../TemplateVariables.scss";

.hero {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    font-family: var(--font-family-body);

    .video {
        object-fit: cover;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .content {
        z-index: $global-z-index-1;
        position: inherit;
        width: 100%;
        height: 100%;
        padding: 20px;
        background-color: #192531b0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-title {
        margin-bottom: 20px;
        font-size: 3rem;
        color: var(--accent-9);
    }

    &-text {
        color: var(--accent-3);
        margin-bottom: 20px;
        font-size: 30px;

        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }

    &-button {
        min-width: 300px;
        min-height: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1rem;

        a {
            text-decoration: none;
            color: var(--accent-5);
        }
    }

    .text {
        margin-bottom: 30px;
        font-size: 1.5rem;
        color: var(--color-accent);
    }
}