.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
}

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    max-width: 1000px;
}

.service {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    &-content {
        flex: 1;

        &-text {
            text-align: justify;
            font-size: 1.2rem;
        }
    }

    &-figure {
        flex: 1;

    }

    &-img {
        border-radius: 15px;
        height: 400px;
        width: 450px;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .services {
        padding: 20px;
    }

    .service {
        padding: 0;
        flex-direction: column !important;

        &-img {
            max-height: 200px;
            width: 300px;
        }

        &-content {

            &-text {
                text-align: center;
            }
        }
    }
}