.header {
    background-color: var(--accent-9);
    font-family: var(--font-family-body);

    container-type: inline-size;
    container-name: header;


    @container header (max-width: 600px) {
        .header {
            &-container {
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }

    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 5px 40px;
        color: #fff;
        max-width: 1200px;
        margin: auto;
    }
}