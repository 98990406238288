.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;
    background-size: cover;
    font-family: var(--font-family-body);

    &-image-filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 60%);
        backdrop-filter: contrast(80%);
    }

    &-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 80px 20px;
    }

    &-content {
        position: inherit;
        max-width: 600px;
        z-index: 1;
    }

    &-title {
        margin-bottom: 20px;
        font-size: 3.75rem;
        color: var(--accent-1);
        transition: font-size 300ms;
        text-align: center;
        text-wrap: balance;
        letter-spacing: -0.025em;
        line-height: 1;
    }

    &-text {
        color: var(--accent-2);
        margin-bottom: 20px;
        font-size: 1.25rem;

        text-align: center;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }

    @media (max-width: 600px) {
        &-title {
            font-size: 2.5rem;
            line-height: 2.5rem;
        }

        &-text {
            font-size: 16px;
        }
    }

    &-button {
        min-width: 300px;
        min-height: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1rem;

        a {
            text-decoration: none;
            color: var(--accent-5);
            font-weight: 600;
            letter-spacing: 0.025em;
        }
    }
}