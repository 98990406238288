.aboutUs {
    text-align: center;
    padding: 50px;
    // font-family: var(--font-family-body);
    // background-color: var(--color-background);
    filter: brightness(95%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-content {
        padding: 20px;
        font-size: 1.5rem;
        text-align: center;
        max-width: 800px;
    }

    @media (max-width: 768px) {
        padding: 25px;

        &-content {
            padding: 10px;
            font-size: 1.1rem;
        }
    }
}