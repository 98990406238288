$size: 1rem;

.main {
    position: relative;

    &-avatar {
        span {
            background-color: initial;
        }
    }

    &-icon {
        width: $size;
        height: $size;
    }

    &-loading {
        position: absolute;
        width: calc($size + 50%);
        height: calc($size + 50%);
        top: -1px;
        left: -2px;
    }
}
