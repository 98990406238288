.card {
    max-width: 800px;
    text-align: center;
}

.radioItem {
    width: 100%;

    span {
        width: 100%;
    }

    button {
        display: none;
    }

    &-selected {
        padding: 3px;
        border-radius: 10px;
        background-color: var(--accent-10);
    }
}

.heading {
    font-size: 60px;
}

@media screen and (max-width: 600px) {
    .heading {
        font-size: 50px;
        line-height: 50px;
    }
}