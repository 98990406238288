.select {
    align-items: center;
    background: #f7f7f7;
    border: 0;
    border-radius: 3px;
    box-shadow: none;
    color: #222f3e;
    display: flex;
    flex: 0 0 auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 28px;
    justify-content: center;
    margin: 6px 1px 5px 0;
    outline: 0;
    overflow: hidden;
    padding: 0;
    text-transform: none;
    width: 90px;
}
