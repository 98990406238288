.button {
    cursor: pointer;
    transition: color 0.3s ease, transform 0.5s ease;

    &:hover {
        transform: translateY(-5px);
    }

    .link {
        text-decoration: none;
    }
}